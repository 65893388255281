@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul,
  ol {
    list-style: revert;
    padding: revert;
  }
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader {
  width: 40px;
  height: 40px;
}

.loader::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-top: 3px solid #3661ed;
  border-right: 8px solid transparent;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.card-shadow {
  box-shadow: 0px 8px 24px 0px rgba(105, 112, 149, 0.15);
  transition: box-shadow 0.3s ease-out;
}

.card-shadow:hover {
  box-shadow: 0px 12px 32px 0px rgba(105, 112, 149, 0.25),
    0px 6px 12px 0px rgba(105, 112, 149, 0.15);
}

.w-fit {
  width: fit-content;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #eef0f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d6dbeb;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b4bdda;
}

.msg table,
.msg th,
.msg td {
  border: 1px solid #d4d9ea;
  padding: 4px;
}

.btn-style {
  transition: box-shadow 0.3s ease;
}

.btn-style:hover {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
}

/* .flowChart .edgePaths {
  fill: #ff0000;
  stroke: #ffff00;
  stroke-width: 4px;
} */

.mermaid svg {
  max-width: 100% !important;
}

.nodeLabel {
  width: 100%;
  display: flex;
  align-items: center;
}

.nodeLabel img {
  max-height: 10px;
  max-width: 10px;
}

.nodeLabel {
  /* background-color: aqua !important; */
  /* border: 1px solid red !important; */
  border-radius: 5px;
}

.flowChart .edge-thickness-normal {
  /* fill: #8E93AF !important; */
  stroke: #8e93af !important;
  stroke-width: 1px !important;
}

.flowChart .marker {
  fill: #8e93af !important;
  stroke: #8e93af !important;
}

.thoughtProcessSpinner {
  --uib-size: 30px;
  --uib-speed: 2s;
  --uib-color: black;

  height: var(--uib-size);
  width: var(--uib-size);
  vertical-align: middle;
  transform-origin: center;
  /* animation: rotate var(--uib-speed) linear infinite; */
}

.thoughtProcessSpinner circle {
  fill: none;
  stroke: #352ce5;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: stretch calc(var(--uib-speed) * 0.75) ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -124px;
  }
}
@layer utilities {
  @variants responsive {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}
